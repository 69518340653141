// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-impact-js": () => import("./../../../src/pages/impact.js" /* webpackChunkName: "component---src-pages-impact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mega-1-js": () => import("./../../../src/pages/mega1.js" /* webpackChunkName: "component---src-pages-mega-1-js" */),
  "component---src-pages-mega-2-js": () => import("./../../../src/pages/mega2.js" /* webpackChunkName: "component---src-pages-mega-2-js" */),
  "component---src-pages-mega-3-js": () => import("./../../../src/pages/mega3.js" /* webpackChunkName: "component---src-pages-mega-3-js" */),
  "component---src-pages-mega-4-js": () => import("./../../../src/pages/mega4.js" /* webpackChunkName: "component---src-pages-mega-4-js" */),
  "component---src-pages-mega-5-js": () => import("./../../../src/pages/mega5.js" /* webpackChunkName: "component---src-pages-mega-5-js" */),
  "component---src-pages-mega-6-js": () => import("./../../../src/pages/mega6.js" /* webpackChunkName: "component---src-pages-mega-6-js" */),
  "component---src-pages-mega-7-js": () => import("./../../../src/pages/mega7.js" /* webpackChunkName: "component---src-pages-mega-7-js" */)
}

